import TiffanyGrant from "./../../assets/guest_pictures/TiffanyGrant.png";
import bridoof from "./../../assets/guest_pictures/bridoof.png";
import foxarcada from "./../../assets/guest_pictures/Foxarcada.png";
import ginkgocrown from "./../../assets/guest_pictures/Ginkgocrown.png";
import petitegrandpa from "./../../assets/guest_pictures/petiteGrandpa.png";
import NadiaMarshall from "./../../assets/guest_pictures/NadiaMarshall.jpeg";
import TomBloom from "./../../assets/guest_pictures/TomBloom.png";
import CoreyWilder from "./../../assets/guest_pictures/CoreyWilder.png";

import { GuestProps } from "./Guest";

export const GUESTSCONFIG: GuestProps[] = [
  {
    name: "Tiffany Grant",
    bodyText: `Best known as Asuka in the Evangelion franchise, Tiffany Grant has been working in anime since 1994. Her 1700+ anime credits include Azumanga Daioh, Blue Seed, Infinite Stratos, Full Metal Alchemist, One Piece, Akame Ga Kill and Girls Und Panzer. 

She has also lended her voice to dozens of vidoe games, including Warframe, Neversong, Brawl Stars, Paladins, and Smite.

Tiffany will be participating in our Voice Actors' panel: “Tiffany Grant, Nadia Marshall, and Corey Wilder: Behind the Voices”! Join us and ask her some of your burning questions about voice acting!`,
    picture: TiffanyGrant,
    role: "Voice Actress",
  },
  {
    name: "Nadia Marshall",
    bodyText: `Nadia Marshall is an award winning voice actor, opera singer, and performer who has been using her voice professionally for over a decade. You've heard her in video game titles like Zenless Zone Zero, Nobody Wants to Die, Cassette Beasts, Grimguard Tactics, Cloudpunk: City of Ghosts, Hauma, and much more. 

Some fun facts about Nadia: She is of British and Indian heritage and is an American and British citizen. Nadia has been dancing Argentine tango since 2012 and enjoys dancing bachata, kizomba, and salsa. She is a vegetarian athlete and has competed in events like the Burpee 5k (how much fun does that sound?!), Toughest Mudder, and World's Toughest Mudder.

Nadia will also be participating in our Voice Actors' panel: “Tiffany Grant, Nadia Marshall, and Corey Wilder: Behind the Voices”! Join us and ask her some of your burning questions about voice acting!`,
    picture: NadiaMarshall,
    role: "Voice Actress",
  },
  {
    name: "Corey Wilder",
    bodyText: `Corey Wilder is an African American Non-Binary Voice Actor who has voiced in anime, animations, and video games.

They've voiced in many properties such as Frieren, Crayola Scribble Scrubbies, Microsoft, One Piece, World War Z, Wistoria, Attack on Titan, Link Click, Dragon Ball Daima and so many more! 

Corey will also be participating in our Voice Actors' panel: “Tiffany Grant, Nadia Marshall, and Corey Wilder: Behind the Voices”! Join us and ask them some of your burning questions about voice acting!`,
    picture: CoreyWilder,
    role: "Voice Actor",
    pictureClasses: "object-top",
  },
  {
    name: "Tom Bloom",
    role: "TTRPG Author",
    bodyText: `Tom Bloom is a comic author and game designer best known for the webcomic Kill Six Billion Demons and the role playing games Lancer RPG, CAIN, and Maleghast.
  
Tom will be presenting a panel on Creating a TTRPG on the main stage from 10:30am to 11:30am! Get ready to ask your questions, whether you be a GM or player!`,
    picture: TomBloom,
  },
  {
    name: "Bridoof",
    role: "Cosplay Contest Judge",
    bodyText: `Bridoof is a self taught seamstress, fabricator, and cosplayer of 10+ years with extensive background in theme park entertainment costumes, now working on the world of corporate mascots for sports and businesses around the world. She has also won her fair share of craftsmanship based contests over the years, which has introduced her to so many wonderful crafters around the country that inspire her every day! Cosplay and crafting is her heart and home and she's so excited to participate as a judge this year for WreckCon 2025!`,
    picture: bridoof,
  },
  {
    name: "Petite Grandpa",
    role: "Cosplay Contest Judge",
    bodyText: `Petite began her cosplay journey in 2022 and focuses on wig making and honing her sewing skills. The community has had its fair share of ups and downs but she enjoys sharing her skills and getting to know new cosplayers! She is happy to return this year as a judge for Wreckcon 2025! Stay tuned to her instagram account to see her compete in the AWA cosplay contest for her first craftsmanship competition.
Photo credit: Honeyheatherworks`,
    picture: petitegrandpa,
  },
  {
    name: "Ginkgocrown",
    role: "Cosplay Contest Judge",
    bodyText: `Ginkgocrown is a self-taught cosplayer who has been in the scene since 2009. They love that every new project brings a chance to learn a new set of skills. They adore cosplay for its chance to allow us to explore ourselves through all different kinds of expressions. It has been amazing to watch the convention scene become as big as it is, and they can't wait to give back to the community that they grew up in at WreckCon!`,
    picture: ginkgocrown,
    pictureClasses: "object-top",
  },
  {
    name: "Fox Arcada",
    role: "Cosplay Contest Judge",
    bodyText: `Fox is a sewing-focused cosmaker of over 10 years. They started self-taught and went on to major in fashion design. They are hugely passionate about all things nerdy, which is how they found their home in the cosplay community, and find so much joy in bringing these characters to life with art. They can’t wait to meet everyone at Wreckcon and are very honored to get to judge the cosplay contest.`,
    picture: foxarcada,
    pictureClasses: "object-top",
  },
];
